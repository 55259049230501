<template>
    <div>

    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {},
    beforeCreate() {
    },

    created() {

    },

    beforeMount() {

    },

    mounted() {

    },

    beforeUpdate() {

    },

    updated() {

    },

    beforeDestroy() {

    },
    destroyed() {

    },

    activated() {

    },
}


</script>

<style scoped>

</style>