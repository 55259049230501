<template>
    <div class="login-view">
        <div class="login-content">
            <div class="login-intro">
                <div>
                    <img src="../assets/logo/logo.png">
                    <h3>I'M AI v1.0.0 正式上线:</h3>
                </div>
                <div>
                    <h3></h3>
                </div>
                <div>
                    <h3></h3>
                </div>
                <div>
                    <h3></h3>
                </div>
                <div>
                    <h3></h3>
                </div>
            </div>
            <div class="login-container">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="账号密码登录" name="accountLogin">
                        <el-form class="login-form" :model="loginForm" status-icon :rules="rules" ref="loginForm"
                                 label-width="60px" @keyup.enter.native="submitForm('loginForm')">
                            <div class="login-brand">欢迎登陆 I'M AI😊</div>
                            <el-form-item label="终端" prop="userName" v-show="false">
                                <el-input type="terminal" v-model="loginForm.terminal" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="用户名" prop="userName">
                                <el-input type="userName" v-model="loginForm.userName" autocomplete="off"
                                          placeholder="用户名"></el-input>
                            </el-form-item>
                            <el-form-item label="密码" prop="password">
                                <el-input type="password" v-model="loginForm.password" autocomplete="off"
                                          placeholder="密码"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('loginForm')">登陆</el-button>
                                <el-button @click="resetForm('loginForm')">清空</el-button>
                            </el-form-item>
                            <div class="register">
                                <router-link to="/register">没有账号,前往注册</router-link>
                            </div>
                        </el-form>
                    </el-tab-pane>

                    <el-tab-pane label="手机号验证码登录" name="phoneLogin">
                        <el-form class="login-form" status-icon :rules="rulesPhone"
                                 label-width="65px"
                                 @keyup.enter.native="submitForm(noteDto)">
                            <div class="login-brand">欢迎登陆 I'M AI😊</div>

                            <el-form-item label="手机号" prop="phone" :rules="phoneRules" :error="phoneError">
                                <el-input type="phoneNumber" v-model="noteDto.phone" autocomplete="off"
                                          placeholder="手机号"/>
                            </el-form-item>
                            <el-form-item label="验证码" prop="verificationCode">
                                <div class="verification-code-container">
                                    <el-input type="verificationCode" v-model="noteDto.code" autocomplete="off"
                                              placeholder="验证码"/>
                                    <el-button @click="sendVerificationCode" :disabled="buttonDisabled">
                                        <span>{{ buttonText }}</span>
                                    </el-button>
                                </div>
                            </el-form-item>

                            <slide-verify
                                v-if="showSlider"
                                :l="42"
                                :r="10"
                                :w="310"
                                :h="155"
                                :imgs="picArray"
                                slider-text="向右滑动完成验证"
                                ref="slideverify"
                                @success="onSuccess"
                                @fail="onFail"
                                @refresh="onRefresh"
                            ></slide-verify>

                            <el-form-item>
                                <el-button type="primary" @click="loginWithPhoneNumber()">登陆</el-button>
                                <el-button @click="resetPhoneForm()">清空</el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <icp></icp>
    </div>
</template>

<script>

import Vue from 'vue';
import SlideVerify from 'vue-monoplasty-slide-verify';
import Icp from '../components/common/Icp.vue'
import axios from "axios";

Vue.use(SlideVerify);
export default {
    name: "login",
    components: {
        Icp
    },
    data() {
        var checkUsername = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('请输入用户名'));
            }
            callback();
        };
        var checkPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            }
            callback();
        };
        var checkPhoneNumber = (rule, value, callback) => {
            if (value) {
                return callback(new Error('请输入手机号'));
            }
            callback();
        };
        var checkVerificationCode = (rule, value, callback) => {
            if (value) {
                return callback(new Error('请输入验证码'));
            }
            callback();
        };
        return {
            showSlider: false, // 新增数据属性，控制滑动验证窗口的显示
            countdownTimer: 0,
            buttonText: '发送验证码',
            buttonDisabled: false,
            countdownInterval: null,
            activeTab: 'accountLogin',
            picArray: [
                require("@/assets/image/1.png")
            ],
            loginForm: {
                terminal: this.$enums.TERMINAL_TYPE.WEB,
                userName: '',
                password: ''
            },

            noteDto: {
                terminal: this.$enums.TERMINAL_TYPE.WEB,
                phone: '',
                code: ''
            },
            phoneRules: [
                {required: true, message: '请输入手机号', trigger: 'blur', type: 'string'}, // 添加type: 'string'
                {pattern: /^[1][3-9][0-9]{9}$/, message: '手机号格式错误', trigger: 'blur'}
            ],
            phoneError: '',
            rules: {
                userName: [{
                    validator: checkUsername,
                    trigger: 'blur'
                }],
                password: [{
                    validator: checkPassword,
                    trigger: 'blur'
                }]
            },
            rulesPhone: {
                phoneNumber: [{
                    validator: checkPhoneNumber,
                    trigger: 'blur'
                }],
                verificationCode: [{
                    validator: checkVerificationCode,
                    trigger: 'blur'
                }]
            }
        };
    },
    methods: {


        onFail() {


        },
        onRefresh() {


        },

        startCountdown() {
            this.countdownTimer = 60;
            this.countdownInterval = setInterval(() => {
                if (this.countdownTimer > 0) {
                    this.countdownTimer--;
                    this.buttonText = `${this.countdownTimer} 秒后重新发送`;
                } else {
                    clearInterval(this.countdownInterval);
                    this.buttonText = '发送验证码';
                    this.buttonDisabled = false;
                }
            }, 1000);
        },
        sendVerificationCode() {
            this.showSlider = true;
            if (this.noteDto.phone.trim() !== '') { // 校验手机号不为空
                console.log('22220', this.phoneRules)
                const phone = this.noteDto.phone.trim();
                if (phone !== '') {
                    this.phoneError = '';// 清除错误信息
                    const baseUrl = 'https://www.zhiwonders.com/api';
                    const phone = this.noteDto.phone;
                    const requestUrl = `${baseUrl}/${phone}/interAspect`;
                    axios.get(requestUrl)
                        .then(response => {
                            if (response.status === 200) {
                                this.$message.success("验证码已发送");
                                this.startCountdown();
                                this.buttonDisabled = true;
                            } else {
                                this.$message.error("发送验证码失败，请重新尝试");
                            }
                        })
                        .catch(error => {
                            this.$message.error("发送验证码失败，请重新尝试");
                        });
                }
            }
        },

        validatePhone() {
            // 手动触发一次手机号的校验
            this.$refs.noteForm.validateField('phone', isValid => {
                if (isValid) {
                    this.phoneError = ''; // 清除错误信息
                } else {
                    this.phoneError = '手机号格式错误'; // 设置错误信息
                }
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (formName === 'loginForm') {
                        this.loginWithUsername();
                    } else {
                        this.loginWithPhoneNumber();
                    }
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        resetPhoneForm() {
            this.noteDto.phone = '';
            this.noteDto.code = '';
        },
        loginWithUsername() {
            this.$http({
                url: "/login",
                method: 'post',
                data: this.loginForm
            }).then((data) => {
                console.log(data)
                this.setCookie('username', this.loginForm.userName);
                this.setCookie('password', this.loginForm.password);
                sessionStorage.setItem("accessToken", data.accessToken);
                sessionStorage.setItem("refreshToken", data.refreshToken);
                this.$message.success("登陆成功");
                this.$router.push("/home/chat");
            });
        },

        showSliderVerification() {
            this.showSlider = true;
            this.buttonDisabled = true; // 发送验证码后禁用按钮
        },

        // 滑动验证成功后的处理
        onSuccess() {
            this.showSlider = false;
            this.startCountdown(); // 开始倒计时
            this.buttonDisabled = true; // 禁用发送验证码按钮
            this.sendVerificationCode(); // 发送验证码
            this.hideSliderVerification(); // 隐藏滑动验证窗口
            this.$message("验证成功!")
        },

        // 隐藏滑动验证窗口
        hideSliderVerification() {
            this.showSlider = false;
            this.buttonDisabled = false; // 重新启用按钮
        },


        async loginWithPhoneNumber() {
            try {
                const response = await axios.post('/auth', this.noteDto, {
                    baseURL: 'https://www.zhiwonders.com/api',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.data.code === 403) {
                    this.$message.error("验证码错误!");
                } else if (response.data.code === 200) {
                    console.log(response)
                    this.setCookie('username', this.noteDto.userName);
                    this.setCookie('password', this.noteDto.password);
                    sessionStorage.setItem("accessToken", response.data.data.accessToken);
                    sessionStorage.setItem("refreshToken", response.data.data.refreshToken);
                    this.$message.success("登录成功!");
                    this.$router.push("/home/chat");
                }
            } catch (error) {
                this.$message.error("出现异常:" + error);
            }
        },


        getCookie(name) {
            let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
            let arr = document.cookie.match(reg)
            if (arr) {
                return unescape(arr[2]);
            }
            return '';
        },
        setCookie(name, value, expiredays) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + expiredays);
            document.cookie = name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
        },
        delCookie(name) {
            var exp = new Date();
            exp.setTime(exp.getTime() - 1);
            var cval = this.getCookie(name);
            if (cval != null) {
                document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
            }
        }
    },
    mounted() {
        this.loginForm.userName = this.getCookie("username");
        this.loginForm.password = this.getCookie("password");
        // 在组件加载完成后延迟执行 validatePhone 方法
        this.$nextTick(() => {
            this.$refs.noteForm && this.validatePhone();
        });
    }
}
</script>

<style scoped lang="scss">
.login-view {
  width: 100%;
  height: 100%;
  background: #E8F2FF;
  background-size: cover;
  box-sizing: border-box;
  background-image: url(../assets/logo/bgimg.jpg);

  .login-content {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10%;

    .login-intro {
      flex: 1;
      padding: 40px;
      max-width: 600px;

      .login-title {
        text-align: center;
        font-weight: 600;
        font-size: 30px;
      }

      .login-icons {
        display: flex;
        align-items: center;

        .login-icon {
          padding-left: 5px;
        }
      }
    }

    .login-container {
      width: 400px;
      background: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      .login-form {
        padding: 30px;
        background: white;
        box-shadow: 0px 0px 1px #ccc;
        border-radius: 3%;
        border: 1px solid #ccc;

        .login-brand {
          line-height: 50px;
          margin: 30px 0 40px 0;
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 2px;
          text-transform: uppercase;
          text-align: center;
        }

        .register {
          display: flex;
          flex-direction: row-reverse;
          line-height: 40px;
          text-align: left;
          padding-left: 20px;
        }
      }
    }
  }
}

.verification-code-container {
  display: flex;
  align-items: center;
}

.verification-code-container .el-input {
  flex-grow: 1;
}

.verification-code-container .el-button {
  margin-left: 10px; /* 可根据需要调整间距 */
  line-height: 2;
  font-size: 10px;
}


</style>
