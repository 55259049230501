<template>
    <div class="video-gallery">
        <div v-for="video in videos" :key="video.id" class="video-item">
            <video :src="video.video_path" :poster="video.cover_path" controls></video>
            <div class="video-info">
                <p>{{ video.video_desc }}</p>
                <p>Duration: {{ video.video_seconds }} seconds</p>
            </div>
        </div>
        <div class="pagination">
            <button @click="fetchVideos(page - 1)" :disabled="page <= 1">Previous</button>
            <span>Page {{ page }} of {{ totalPages }}</span>
            <button @click="fetchVideos(page + 1)" :disabled="page >= totalPages">Next</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoGallery',
    data() {
        return {
            videos: [],        // 存储视频记录
            page: 1,           // 当前页码
            totalPages: 1,     // 总页数
            pageSize: 10,      // 每页显示的视频数量
        };
    },
    methods: {
        // 获取视频数据的函数，需要根据实际情况实现
        fetchVideos(page) {
            // 这里应该是一个API调用，以下为示例代码
            // axios.get(`/api/videos?page=${page}&pageSize=${this.pageSize}`)
            //   .then(response => {
            //     this.videos = response.data.records;
            //     this.page = response.data.page;
            //     this.totalPages = response.data.totalPages;
            //   })
            //   .catch(error => {
            //     console.error('There was an error!', error);
            //   });

            // 模拟数据获取
            // 实际开发中，你需要根据后端返回的数据格式来设置这些值
            this.videos = this.mockData(page, this.pageSize);
            this.page = page;
            // 假设总共有40条数据，每页10条，那么总页数是4
            this.totalPages = 4;
        },
        // 模拟后端返回的数据
        mockData(page, pageSize) {
            // 这里返回一个模拟的视频数据数组
            // 实际开发中，这里应该是后端返回的数据
            return Array.from({ length: pageSize }, (_, index) => ({
                id: (page - 1) * pageSize + index + 1,
                user_id: 'user-' + (page - 1) * pageSize + index,
                audio_id: 'audio-' + (page - 1) * pageSize + index,
                video_desc: 'Video description ' + (page - 1) * pageSize + index,
                video_path: 'path/to/video/' + (page - 1) * pageSize + index + '.mp4',
                video_seconds: 60,
                video_width: 640,
                video_height: 360,
                cover_path: 'path/to/cover/' + (page - 1) * pageSize + index + '.jpg',
                status: 1,
                create_time: new Date().toISOString()
            }));
        }
    },
    created() {
        // 组件创建完成后立即获取第一页的数据
        this.fetchVideos(this.page);
    }
};
</script>

<style scoped>
.video-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.video-item {
    margin: 10px;
    width: 300px;
}

.video-info {
    text-align: left;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 20px;
}

button {
    margin: 0 10px;
}
</style>
